// Accordions

const accordions = document.querySelectorAll(".accordion");

accordions.forEach(accordion => {
    const button = accordion.querySelector("button");
    const content = accordion.querySelector(".accordion-content");
    const preview = accordion.querySelector('.preview');
    button.addEventListener("click", function() {
        if( accordion.classList.contains("show") ) {
            accordion.classList.remove("show");
            content.classList.remove("show");
            preview.hidden = false;
        } else {
            accordion.classList.add("show");
            content.classList.add("show");
            preview.hidden = true;
        }
    })
})

// Demo Account
const copyAccount = document.querySelector("#copy-account");
const account = document.querySelector("#account");
const accountName = account.innerHTML;

copyAccount.addEventListener("click", () => {
    copyContent();
});

const copyContent = async () => {
    try {
      await navigator.clipboard.writeText(accountName);
      copyAccount.innerHTML = "copied";
      console.log('Content copied to clipboard');
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }

// Demo Password

const togglePassword = document.querySelector("#toggle-password");
const passwordShown = togglePassword.querySelector(".shown");
const passwordHidden = togglePassword.querySelector(".hidden");
const passwordMasked = document.querySelector("#mask");
const passwordUnmasked = document.querySelector("#unmask");

togglePassword.addEventListener("click", function() {
    togglePassword.classList.toggle("active");
    passwordMasked.classList.toggle("active");
    passwordUnmasked.classList.toggle("active");
})